// This component is responsible for displaying the page if we get a valid bearer token back, and the user has access

import React, {useState, useEffect} from 'react';
import {useUser} from "@principal-aws-oidc-auth/react"
import CompetitorPricing from './CompetitorPricing'

export default function AuthRouter(props) {
    const user = useUser()
    const [hasToken, setHasToken] = useState()
    const [hasRole, setHasRole] = useState()

    useEffect(() => {
        if (user != null && user.access_token != null) {
            //This is needed to get the role from the bearer token.
            //This command may error out if you don't have access to app and testing locally.
            //setHasRole(JSON.parse(atob(user.access_token.split('.')[1])).roles[0])
            const payload = JSON.parse(atob(user.access_token.split('.')[1]))
            if (payload.roles != null)
                setHasRole(payload.roles[0])

            setHasToken(user.access_token)
        }
    },[user])

    function checkDisplayPage() {
        if (hasToken != null && hasRole != null)
            return <CompetitorPricing/>
        else if (hasToken != null && hasRole == null)
            return(
            <div className="pds-unauthenticatedError-background">
              <section className="pds-imgStanTheMan pds-util-padding-top-20 pds-util-padding-bottom-10 stanBackground">
                <div className="pds-typography-center">
                  <div>
                    <h1 className="pds-typography-h2 pds-util-margin-top-10 pds-util-margin-bottom-0 center">Pardon me?</h1>
                  </div>
                  <div className="pds-util-padding-horz-10">
                    <p className="pds-util-margin-vert-0 center">It looks like you don't have permission to view this page. If you think you should have permission, contact your primary administrator.</p>
                  </div>
                </div>
              </section>
            </div>
            )
        else if (hasToken == null)
            return <p>Checking authorization...Redirecting...</p>
        else
            return <p>Something went wrong</p>
    }

    return(
    <div>
    {
        checkDisplayPage()
    }
    </div>
    )
}

