import './App.css';
import React from 'react';
import {AuthProvider} from "@principal-aws-oidc-auth/react";
import AuthRouter from './components/AuthRouter'

function App() {
    return (
        <AuthProvider settings={{
            authority: 'https://login.microsoftonline.com/3bea478c-1684-4a8c-8e85-045ec54ba430/v2.0',
            client_id: '0ef50359-540a-4f58-8f80-dec54a60a9e6',
            scope: 'api://competitor-pricing/read api://competitor-pricing/write'
        }}>
    <AuthRouter/>
    </AuthProvider>
);

}

export default App;