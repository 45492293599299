import React from "react"

export default function CompetitorPricingResult(props){
    const originalInputs = props.originalInputs
    const result = props.result

    return(
    <div id="result" className="result" align="center">
        <p>
            <b>Hard Copy Proposal:</b> {originalInputs.hardCopyProposal}
            <b> Provider:</b> {originalInputs.provider}
            <b> Competitor Model:</b> {originalInputs.competitorModel}
            <b> Discounts: </b>
            {originalInputs.selectedDiscounts.some(discounts => discounts.state) ? (
                <span>
                    {originalInputs.selectedDiscounts
                        .filter(discounts => discounts.state)
                        .map(discounts => discounts.name)
                        .join(', ')}
                </span>
            ) : (
                <span>Open Architecture</span>
            )}
            <br></br>
            <b> Transfer Assets:</b> ${originalInputs.transferAssets.toLocaleString()}
            <b> Annual Deposits:</b> ${originalInputs.annualDeposits.toLocaleString()}
            <b> Participants:</b> {originalInputs.ppts.toLocaleString()}
        </p>

        <br></br>

        <div>
            <p className="table-result-header"> Predicted Recordkeeping Fees:</p>
            <table className="table_bg">
                <tr>
                    <th>Provider</th>
                    <th>Percent</th>
                    <th>Dollars</th>
                </tr>
                <tr>
                    <td>Fidelity</td>
                    <td>{result.predictions.fidelity_pct}%</td>
                    <td>${result.predictions.fidelity_dollars.toLocaleString('en-US', { style: 'decimal', maximumFractionDigits : 2, minimumFractionDigits : 2 })}</td>
                </tr>
                <tr>
                    <td>Empower</td>
                    <td>{result.predictions.empower_pct}%</td>
                    <td>${result.predictions.empower_dollars.toLocaleString('en-US', { style: 'decimal', maximumFractionDigits : 2, minimumFractionDigits : 2 })}</td>
                </tr>
                <tr>
                    <td>John Hancock</td>
                    <td>{result.predictions.johnhancock_pct}%</td>
                    <td>${result.predictions.johnhancock_dollars.toLocaleString('en-US', { style: 'decimal', maximumFractionDigits : 2, minimumFractionDigits : 2 })}</td>
                </tr>
                <tr>
                    <td>Voya</td>
                    <td>{result.predictions.voya_pct}%</td>
                    <td>${result.predictions.voya_dollars.toLocaleString('en-US', { style: 'decimal', maximumFractionDigits : 2, minimumFractionDigits : 2 })}</td>
                </tr>
                <tr>
                    <td>Transamerica</td>
                    <td>{result.predictions.transamerica_pct}%</td>
                    <td>${result.predictions.transamerica_dollars.toLocaleString('en-US', { style: 'decimal', maximumFractionDigits : 2, minimumFractionDigits : 2 })}</td>
                </tr>
                <tr>
                    <td>T. Rowe Price</td>
                    <td>{result.predictions.troweprice_pct}%</td>
                    <td>${result.predictions.troweprice_dollars.toLocaleString('en-US', { style: 'decimal', maximumFractionDigits : 2, minimumFractionDigits : 2 })}</td>
                </tr>
                <tr>
                    <td>Vanguard</td>
                    <td>{result.predictions.vanguard_pct}%</td>
                    <td>${result.predictions.vanguard_dollars.toLocaleString('en-US', { style: 'decimal', maximumFractionDigits : 2, minimumFractionDigits : 2 })}</td>
                </tr>
            </table>
        </div>
    </div>
    )
}